import React from "react";
import { arcsHome } from '../../utils/data';
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";

function HomeCard(): JSX.Element {
  const { t } = useTranslation();

    return (
        <div 
            className="w-full h-screen flex flex-col items-center justify-evenly bg-no-repeat bg-center bg-cover px-[1rem]"
        >
        <p 
            className="text-outline ultra text-orange text-[4rem] text-center font-extrabold"
        >
            {arcsHome(t).title}
        </p>
        <Button scrollElement="homeInfos" title={t('learnMore')} />
        </div>
    );
}

export { HomeCard };