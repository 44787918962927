import React, { useContext, useEffect } from "react";
import { Footer } from "../components/footer/Footer";
import { LanguageSwitch } from "../components/LanguageSwitch";
import { Link, useLocation } from "react-router-dom";
import { NavBar } from "../components/navBar/NavBar";
import { arcsInfos } from "../utils/data";
import { MenuContext } from "../utils/context";
import { BurgerMenu } from "../components/navBar/BurgerMenu";

interface ILayoutProps {
  children: JSX.Element;
}

function Layout({ children }: ILayoutProps): JSX.Element {
  const location = useLocation();
  const { updateActiveMenu } = useContext(MenuContext);

  useEffect(() => {
    updateActiveMenu(location.pathname);
  },[]);

  return (
    <div className="w-full flex flex-col justify-between ">
      <div 
        className={`w-full ${location.pathname !== '/' && location.pathname !== '/lesarcs'  ? 'md:bg-cover bg-no-repeat md:bg-top' : ''} relative`}
        style={{backgroundImage: `${location.pathname !== '/' && location.pathname !== '/lesarcs' ? 'url(images/background-les-arcs.webp)' : ''}`}}
      >
        {location.pathname === '/' && (
          <video
            className="absolute top-0 left-0 w-full h-screen object-cover z-10"
            autoPlay
            loop
            muted
          >
            <source src="videos/background3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <div 
          className={`bg-white/30 relative z-50 w-full flex items-center space-x-[.5rem] ${location.pathname !== '/' && location.pathname !== '/lesarcs' ? '' : 'absolute'} justify-between p-[1rem]`}
        >
          <Link to="/">
            <img src={arcsInfos.logo} alt="logo" className="w-[10rem] md:w-[15rem] rounded-[.5rem]"/>
          </Link>
          <div className="md:block hidden">
            <NavBar />
          </div>
          <div className="hidden md:block">
            <LanguageSwitch />
          </div>
          <div className="block md:hidden">
            <BurgerMenu />
          </div>
        </div>
        <div className="relative z-40 bg-white/30">{children}</div>
      </div>
      <Footer />
    </div>
  );
}

export { Layout };