import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { modifyRatesElements } from "../../utils/functions";
import { ratesElements } from '../../utils/data';
import { IFormData, IRatesElement, IRatesInfos, IRatesMain } from "../../utils/types";
import { v4 } from "uuid";
import emailjs from 'emailjs-com';

function QuoteForm2(): JSX.Element {
  const { t } = useTranslation();

  const [table, setTable] = useState<IRatesInfos[]>([]);
  const [formData, setFormData] = useState<IFormData>({
    name: "",
    firstname: '',
    phone: '',
    email: "",
    quote: [],
  });

  const generateTableHtml = (data: IFormData): string => {
    const quoteRows = data.quote
      .map((item: { title: string; quantity: number; duration: number; total: number }) => {
        return `
          <tr>
            <td style="border: 1px solid #D57556; padding: 8px; text-transform: uppercase;">${item.title}</td>
            <td style="border: 1px solid #D57556; padding: 8px; text-align: center;">${item.quantity}</td>
            <td style="border: 1px solid #D57556; padding: 8px; text-align: center;">${item.duration || '-'}</td>
            <td style="border: 1px solid #D57556; padding: 8px; text-align: center;">${item.total.toFixed(2)} €</td>
          </tr>
        `;
      })
      .join('');

    const totalTTC = data.quote.reduce((sum: number, item: { total: number }) => sum + item.total, 0).toFixed(2);
    const totalHT = (parseFloat(totalTTC) * 0.8).toFixed(2);
    const tva = (parseFloat(totalTTC) * 0.2).toFixed(2);

    return `
      <div style="font-family: Arial, sans-serif; font-size: 14px; color: #333;">
        <h2 style="color: #D57556; text-align: center;">Détails du devis</h2>
        <p><strong>Nom :</strong> ${data.name}</p>
        <p><strong>Prénom :</strong> ${data.firstname || ''}</p>
        <p><strong>Email :</strong> ${data.email}</p>
        <p><strong>Téléphone :</strong> ${data.phone || ''}</p>
        <table border="1" cellpadding="8" cellspacing="0" style="border-collapse: collapse; width: 100%; margin-top: 16px;">
          <thead>
            <tr style="background-color: #D57556; color: #fff;">
              <th style="border: 1px solid #D57556; padding: 8px; text-align: left;">Prestations réservées</th>
              <th style="border: 1px solid #D57556; padding: 8px; text-align: center;">Quantité</th>
              <th style="border: 1px solid #D57556; padding: 8px; text-align: center;">Durée (semaines)</th>
              <th style="border: 1px solid #D57556; padding: 8px; text-align: center;">Total TTC (€)</th>
            </tr>
          </thead>
          <tbody>
            ${quoteRows}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3" style="border: 1px solid #D57556; padding: 8px; text-align: right; font-weight: bold; background-color: #f9f9f9;">Total HT</td>
              <td style="border: 1px solid #D57556; padding: 8px; text-align: center; font-weight: bold;">${totalHT} €</td>
            </tr>
            <tr>
              <td colspan="3" style="border: 1px solid #D57556; padding: 8px; text-align: right; font-weight: bold; background-color: #f9f9f9;">Total TVA</td>
              <td style="border: 1px solid #D57556; padding: 8px; text-align: center; font-weight: bold;">${tva} €</td>
            </tr>
            <tr>
              <td colspan="3" style="border: 1px solid #D57556; padding: 8px; text-align: right; font-weight: bold; background-color: #f9f9f9;">Total TTC</td>
              <td style="border: 1px solid #D57556; padding: 8px; text-align: center; font-weight: bold;">${totalTTC} €</td>
            </tr>
          </tfoot>
        </table>
      </div>
    `;
  };
  

    const handleQuoteChange = (categoryIndex: number, itemIndex: number, subItemIndex: number, field: string, value: string) => {
        const quantity = field === "quantity" ? parseInt(value) || 0 : formData.quote.find(q => q.title === table[categoryIndex].list[itemIndex].list[subItemIndex].title)?.quantity || 0;
        const duration = field === "duration" ? parseInt(value) || 0 : formData.quote.find(q => q.title === table[categoryIndex].list[itemIndex].list[subItemIndex].title)?.duration || 0;
    
        const price = parseFloat(table[categoryIndex].list[itemIndex].list[subItemIndex]?.price.replace(',', '.').replace('€', '') || "0");
        const total = duration > 0 ? price * quantity * duration : price * quantity;
    
        const updatedQuote = [...formData.quote];
        const quoteIndex = updatedQuote.findIndex(q => q.title === table[categoryIndex].list[itemIndex].list[subItemIndex].title);
    
        if (quoteIndex >= 0) {
        updatedQuote[quoteIndex] = {
            ...updatedQuote[quoteIndex],
            quantity,
            duration,
            total,
        };
        } else {
        updatedQuote.push({
            title: table[categoryIndex].list[itemIndex].list[subItemIndex].title,
            quantity,
            duration,
            total,
        });
        }
    
        setFormData({
        ...formData,
        quote: updatedQuote,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    
        const csvData = generateTableHtml(formData);
    
        const templateParams = {
          to_name: "Bénedicte",
          from_name: formData.name,
          from_firstname: formData.firstname,
          message: "Voici les données du formulaire en format CSV.",
          csv_data: csvData,
        };
    
        emailjs
          .send(
            process.env.REACT_APP_SERVICE || '',
            process.env.REACT_APP_TEMPLATE || '',
            templateParams,
            process.env.REACT_APP_KEY || ''
          )
          .then(
            (result) => {
              console.log("Email envoyé avec succès :", result.text);
            },
            (error) => {
              console.error("Erreur lors de l'envoi de l'email :", error);
            }
          );
      };
  

    useEffect(() => {
        setTable(modifyRatesElements(ratesElements(t), t));
    }, [t]);

  return (
    <form onSubmit={handleSubmit} className="form flex flex-col space-y-[1rem] w-full">
        <div className="flex items-center space-x-[1rem]">
            <div className="flex flex-col space-y-[.2rem] w-full text-[1.5rem]">
                <label htmlFor="name">{t('name')}</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                    className="rounded-[.5rem] border-orange border px-[.5rem]"
                />
            </div>
            <div className="flex flex-col space-y-[.2rem] w-full text-[1.5rem]">
                <label htmlFor="firstname">{t('firstName')}</label>
                <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={formData.firstname}
                    onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                    required
                    className="rounded-[.5rem] border-orange border px-[.5rem]"
                />
            </div>
        </div>
        <div className="flex items-center space-x-[1rem] text-[1.5rem] pb-[4rem]">
            <div className="flex flex-col space-y-[.2rem] w-full text-[1.5rem]">
                <label htmlFor="phone">{t('phone')}</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    required
                    className="rounded-[.5rem] border-orange border px-[.5rem]"
                />
            </div>
            <div className="flex flex-col space-y-[.2rem] w-full text-[1.5rem]">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                    className="rounded-[.5rem] border-orange border px-[.5rem]"
                />
            </div>
        </div>
        <table className="table-fixed border border-orange w-full">
            <thead>
            <tr>
                <th className="border border-orange p-[.5rem] w-[40%]">Prestations réservées</th>
                <th className="border border-orange p-[.5rem]">Prix unitaire € TTC / semaine</th>
                <th className="border border-orange p-[.5rem]">Quantité</th>
                <th className="border border-orange p-[.5rem]">Durée de réservation</th>
                <th className="border border-orange p-[.5rem]">TOTAL TTC</th>
            </tr>
            </thead>
            <tbody>
            {table.map((category: IRatesInfos, categoryIndex: number) => (
                <React.Fragment key={v4()}>
                    {category.list.map((item: IRatesMain, itemIndex: number) => (
                        <React.Fragment key={v4()}>
                            <tr>
                                <th colSpan={5} className="border border-orange p-[.5rem] bg-orange w-full text-white font-bold">
                                {item.title}
                                </th>
                            </tr>
                        <React.Fragment key={v4()}>
                            {item.list.map((subItem: IRatesElement, subItemIndex: number) => (
                                <tr key={v4()}>
                                    <td className="border border-orange p-[.5rem] w-[40%]">
                                        {subItem.title.toUpperCase()}
                                        <div>{subItem.desc}</div>
                                    </td>
                                    <td className="border border-orange p-[.5rem] text-center">{subItem.price}</td>
                                    <td className="border border-orange p-[.5rem] text-center">
                                        <input
                                            type="number"
                                            name="quantity"
                                            min={0}
                                            value={
                                                formData.quote.find(q => q.title === subItem.title)?.quantity || 0
                                            }
                                            onChange={(e) =>
                                                handleQuoteChange(categoryIndex, itemIndex, subItemIndex, "quantity", e.target.value)
                                            }
                                            className="px-[.5rem] py-[.3rem] text-center"
                                        />
                                    </td>
                                    <td 
                                        className={`border border-orange p-[.5rem] text-center ${item.title === t('keyHandover') || item.title === t('inventory') || item.title === t('caretaking') ? 'bg-orange/30' : ''}`}
                                    >
                                        {item.title !== t('keyHandover') && item.title !== t('inventory') && item.title !== t('caretaking') &&(
                                            <input
                                                type="number"
                                                name="duration"
                                                min={0}
                                                value={
                                                    formData.quote.find(q => q.title === subItem.title)?.duration || 0
                                                }
                                                onChange={(e) =>
                                                    handleQuoteChange(categoryIndex, itemIndex, subItemIndex, "duration", e.target.value)
                                                }
                                                className="px-[.5rem] py-[.3rem] text-center"
                                            />
                                        )}
                                    </td>
                                    <td className="border border-orange p-[.5rem] text-center">
                                        {formData.quote[formData.quote.findIndex((element) => element.title === subItem.title)]?.total.toFixed(2) || "0.00"} €
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={4} className="border border-orange p-[.5rem] bg-orange w-full text-white font-bold w-[40%]">TOTAL TTC</td>
                <td className="border border-orange p-[.5rem] text-center">
                {formData.quote.reduce((sum, item) => sum + item.total, 0).toFixed(2)} €
                </td>
            </tr>
            </tfoot>
        </table>
      <button type="submit" className="mt-[1rem] bg-orange text-white p-[.5rem] rounded w-[30%] font-bold mx-auto">Envoyer</button>
    </form>
  );
}

export { QuoteForm2 };
