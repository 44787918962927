import React, { useState } from "react";
import { Burger, Cross } from "../../assets/svgComponents";
import { navBarInfos } from "../../utils/data";
import { INavBarInfos } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LanguageSwitch } from "../LanguageSwitch";
import { v4 } from "uuid";

function BurgerMenu() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="relative">
        <Burger
          className="w-[3rem] h-[3rem] fill-orange cursor-pointer"
          onClick={toggleMenu}
        />
        {isOpen && (
          <div
            className="fixed top-0 left-0 w-screen h-screen bg-black/50 z-[700]"
            onClick={toggleMenu}
          ></div>
        )}
        <div
          className={`fixed top-0 right-0 w-[80%] h-screen bg-white z-[800] shadow-lg transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300`}
        >
          <div className="w-full flex justify-end p-[1rem]">
            <Cross
              className="w-[3rem] h-[3rem] fill-orange cursor-pointer"
              onClick={toggleMenu}
            />
          </div>
          <ul className="flex flex-col">
            {navBarInfos(t).map((infos: INavBarInfos) => (
              <Link
                key={v4()}
                to={infos.link}
                className="p-[1rem] border-b hover:bg-gray-100 text-[1.5rem]"
                onClick={toggleMenu}
              >
                {infos.title}
              </Link>
            ))}
          </ul>
          <div className="w-full flex justify-end p-[1rem]">
            <LanguageSwitch />
          </div>
        </div>
      </div>
    );
}  

export { BurgerMenu };


