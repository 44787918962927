import React from "react";
import { ContactCard } from "./ContactCard";
import { InfosCardFooter } from "./InfosCardFooter";
import { arcsInfos } from '../../utils/data';
import { useLocation } from "react-router-dom";

function Footer(): JSX.Element {
  const location = useLocation();
  const dev: string = "</>Charlie";

  return (
    <footer
      className={`w-full h-fit py-[1rem] md:px-[3rem] px-[.5rem] flex flex-col md:flex-row md:items-start space-y-[1rem] md:space-y-0 md:space-x-[3rem] md:justify-around ${
        location.pathname === "/" ? "border-t border-orange" : ""
      }`}
    >
      <div className="order-1 flex-grow">
        <InfosCardFooter infos={arcsInfos} />
      </div>
      <div
        className={`flex flex-col self-center md:self-end md:items-center justify-center md:justify-start space-x-[.3rem] order-3 md:order-2`}
      >
        <div className="flex items-center space-x-[.3rem]">
          <p>site maintenu par </p>
          <a href="https://portfolio-charlie-boudou.vercel.app/">{dev}</a>
        </div>
      </div>
      <div className="order-2 md:order-3 flex-grow">
        <ContactCard infos={arcsInfos} />
      </div>
    </footer>
  );
}

export { Footer };
