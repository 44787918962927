import { TFunction } from "i18next";
import { IRatesInfos, IRatesMain } from './types';

export const modifyRatesElements = (rates: IRatesInfos[], t: TFunction): IRatesInfos[] => {
    return rates
        .filter((rate: IRatesInfos) => 
            ![t('laundry'), t('servicesPlus'), t('services')].includes(rate.title)
        )
        .map((rate: IRatesInfos) => {
            if (rate.title === `${t('linenandmaterialRental')} *`) {
                return {
                    ...rate,
                    list: rate.list.map((subList: IRatesMain) => {
                        if (subList.title === t('linen')) {
                            return {
                                ...subList,
                                list: [
                                    ...subList.list.filter(
                                        item => !t('rentalLinenEl').includes(item.title)
                                    ),
                                    { title: t('sheetsEl3'), desc: t('sheetsElDesc2'), price: "23,90 €" },
                                    { title: t('sheetsEl4'), desc: t('sheetsElDesc2'), price: "23,90 €" },
                                ]
                            };
                        }
                        if (subList.title === t('equipment')) {
                            return {
                                ...subList,
                                list: [
                                    ...subList.list.filter(
                                        item => ![t('equipmentEl'), t('equipmentEl3')].includes(item.title)
                                    ),
                                    { title: t('stroller'), desc: "", price: "35,00 €" },
                                    { title: t('highChair'), desc: "", price: "37,40 €" },
                                    { title: t('rentalLinenEl'), desc: t('babyPack'), price: "37,00 €" }
                                ]
                            };
                        }
                        return subList;
                    })
                };
            } else if (rate.title === t('ownerServices')) {
                return {
                    ...rate,
                    list: rate.list.filter(subList => subList.title !== t('repairs'))
                };
            }
            return rate;
        });
};

