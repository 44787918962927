import React from "react";
import { IInfos } from "../../utils/types";
import { v4 } from "uuid";

interface IContactCardProps {
    infos: IInfos;
}

function ContactCard({infos}: IContactCardProps): JSX.Element {

  return (
    <div className="flex flex-col text-center">
        <p className="font-bold"><span className={`${infos.title.includes('ARCS') ? 'text-orange' : 'text-pink'}`}>CONTACT</span> : {infos.name}</p>
        <div>
            {infos.tel.map((tel: string) => (
                <p key={v4()}>Tel: {tel}</p>
            ))}
            <a href={`mailto:${infos.contact}`}>{infos.contact}</a>
        </div>
    </div>
  );
}

export { ContactCard };