import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import { modifyRatesElements } from "../utils/functions";
import { ratesElements } from '../utils/data';
import { QuoteForm2 } from "../components/quote/QuoteForm2";

function Quote(): JSX.Element {
  const { t } = useTranslation();

  const [isQuoteSend, setIsQuoteSend] = useState<boolean>(false);
  const [table, setTable] = useState<any>();

  const [formData, setFormData] = useState({
    name: "",
    firstname: '',
    phone: '',
    email: "",
    quote: [
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
        {
            title: '',
            quantity: 0,
            total: 0,
        },
    ],
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setTable(modifyRatesElements(ratesElements(t), t));
  }, []);

  const generateTableHtml = (data: any) => {
    return `
    <table border="1" cellpadding="8" cellspacing="0" style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif; font-size: 14px;">
      <thead>
        <tr>
          <th style="background-color: #f2f2f2; text-align: left; border: 1px solid #dddddd; padding: 8px;">Name</th>
          <th style="background-color: #f2f2f2; text-align: left; border: 1px solid #dddddd; padding: 8px;">First Name</th>
          <th style="background-color: #f2f2f2; text-align: left; border: 1px solid #dddddd; padding: 8px;">Email</th>
          <th style="background-color: #f2f2f2; text-align: left; border: 1px solid #dddddd; padding: 8px;">Message</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td style="border: 1px solid #dddddd; padding: 8px;">${data.name}</td>
            <td style="border: 1px solid #dddddd; padding: 8px;">${data.firstName || ""}</td>
            <td style="border: 1px solid #dddddd; padding: 8px;">${data.email}</td>
            <td style="border: 1px solid #dddddd; padding: 8px;">${data.message}</td>
          </tr>
      </tbody>
    </table>
  `;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const csvData = generateTableHtml(formData);

    const templateParams = {
      to_name: "Bénedicte",
      from_name: formData.name,
      from_firstname: formData.firstname,
      message: "Voici les données du formulaire en format CSV.",
      csv_data: csvData,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE || '',
        process.env.REACT_APP_TEMPLATE || '',
        templateParams,
        process.env.REACT_APP_KEY || ''
      )
      .then(
        (result) => {
          console.log("Email envoyé avec succès :", result.text);
        },
        (error) => {
          console.error("Erreur lors de l'envoi de l'email :", error);
        }
      );
  };

  console.log('table', table);
  return (
    <div className="w-full h-screen overflow-y-scroll flex flex-col items-center space-y-[2rem] pb-[2rem]">
        <div className="w-[90%] rounded-[.5rem] bg-white/90 flex flex-col items-center justify-center p-[1rem]">
            <div 
                className="text-orange font-bold text-[3rem] text-center pb-[2rem]"
                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
            >
                {!isQuoteSend ? (
                    <>
                        <p>{t('haveQuestion')}</p>
                        <p>{t('answer')}</p>
                    </>
                ):(
                    <p>{t('successfullSent')}</p>
                )}
            </div>
            <QuoteForm2 />
        </div>
    </div>
  );
}

export { Quote };